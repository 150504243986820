// src/AuthPopup.js


// src/AuthPopup.js
import React, { useEffect, useState, useRef } from 'react';



let authUiInstance = null; // Singleton for AuthUI instance

function DeleteInstructionsPage() {

  return (
   <div>
    <span>Log in and click 'delete account' in the footer of the page.</span>
   </div>
  );
}

export default DeleteInstructionsPage;

